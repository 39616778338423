import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
    const { nome } = {
        nome: localStorage.getItem('nome')
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('cpf');
        localStorage.removeItem('nivel');
        localStorage.removeItem('nome');
        window.location.href = '/Login';
    };

    return (
        <header className="header">
            <nav className="navbar navbar-expand navbar-light">
                <div className='navbar-custom'>
                    <div className="container-fluid d-flex justify-content-between">
                        <span className="navbar-brand"></span>
                        <div className="d-flex align-items-center">
                            <div className="px-4 text-dark">
                                {nome}
                            </div>

                            <div className="nav-item" style={{ listStyle: 'none' }}>
                                <div className="vr d-none d-lg-flex h-100 mx-lg-2"></div>
                            </div>

                            <div className="nav-item dropdown">
                                <div className="d-flex align-items-center px-3 pe-0 text-dark gap-2 dropdown-toggle" 
                                     data-bs-toggle="dropdown" 
                                     aria-expanded="false">
                                    <i className="bi fs-1 bi-person-circle cursor-pointer"></i>
                                </div>
                                <div className="dropdown-menu dropdown-menu-end">
                                    <Link className="dropdown-item" to="/perfil">
                                        <i className="bi bi-person-fill"></i> Meu Perfil
                                    </Link>
                                    <div className="dropdown-divider"></div>
                                    <button className="dropdown-item" onClick={handleLogout}>
                                        <i className="bi bi-box-arrow-right"></i> Sair
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Header;