import React from 'react';
import ReactDOM from 'react-dom/client';
import './Styles/index.css';
import './Styles/PageFull.css';
import App from './App';

import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.bundle.min";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<App />
);