import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Api from '../../Api';
import { Modal } from 'react-bootstrap';

import OS from "./OScomponents/OS";
import FormFields from "./OScomponents/FormFields";
import Pelicula from "./OScomponents/Pelicula";
import Certificado from "./OScomponents/pdfUtils";
import CurrencyInput from '../../Components/CurrencyInput';
import TextareaAutosize from "react-textarea-autosize";
import { useForm } from 'react-hook-form';
import Select from 'react-select';


function NovaOS() {
	const { watch, setValue } = useForm()

	const [os, setOs] = useState(new OS());

	useEffect(() => { //buscando informações dos clientes e funcionários
		const fetchClientes = async () => {
			try {
				const clientesResponse = await Api.get("ListarClientes");
				setOs((prevOs) => ({
					...prevOs,
					clientes: clientesResponse.data,
				}));
			} catch (error) {
				console.log(error);
			}
		};
		fetchClientes();

		const fetchFuncionarios = async () => {
			try {
				const funcionariosResponse = await Api.get("ListarFuncionarios");
				setOs((prevOs) => ({
					...prevOs,
					funcionarios: funcionariosResponse.data,
				}));
			} catch (error) {
				console.log(error);
			}
		};
		fetchFuncionarios();

		const fetchProdutos = async () => {
			try {
				const response = await Api.get("ListarProdutos?modo=OS")
				//deve fazer o set dos produtos que o campo "tipo" for "unit"
				const filteredProdutos = response.data
					.filter((produto) => produto.tipo === "unit")
					.map((produto) => ({
						...produto,
						valor_venda: `R$ ${parseFloat(produto.valor_venda).toLocaleString("pt-BR", {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}`,
					}));

				setOs((prevOs) => ({
					...prevOs,
					produtos: filteredProdutos,
				}));
			} catch (error) {
				console.log(error);
			}
		}
		fetchProdutos();

		const fetchServicos = async () => {
			try {
				const response = await Api.get("ListarServicos")
				const filteredServicos = response.data
					.map((servico) => ({
						...servico,
						valor: `R$ ${parseFloat(servico.valor).toLocaleString("pt-BR", {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}`,
					}));
				setOs((prevOs) => ({
					...prevOs,
					servicos: filteredServicos,
				}));
			} catch (error) {
				console.log(error);
			}
		}
		fetchServicos();

		const fetchVeiculos = async () => {
			try {
				const response = await Api.get("ListarVeiculos")
				setOs((prevOs) => ({
					...prevOs,
					veiculos: response.data,
				}));
			} catch (error) {
				console.log(error);
			}
		}
		fetchVeiculos();

		const fetchPeliculas = async () => {
			try {
				const response = await Api.get("ListarProdutos?modo=OS")
				const filter = response.data.filter((produto) => produto.tipo === "material")
				setOs((prevOs) => ({
					...prevOs,
					peliculas: filter,
				}));
			} catch (error) {
				console.log(error);
			}
		}
		fetchPeliculas();

	}, []);


	//informacoes basicas
	const handleFieldChange = (fieldName, value) => { //interface para a classe OS
		console.log(fieldName, value)
		setOs((prevOs) => ({
			...prevOs,
			informacoesBasicas: {
				...prevOs.informacoesBasicas,
				[fieldName]: value,
			},
		}));
	};

	//produtos
	const handleProdutoChange = (id, field, value, produto) => {
		const updatedOs = { ...os };
		const produtoIndex = updatedOs.produtosSelecionados.findIndex((produto) => produto.id === id);
		updatedOs.produtosSelecionados[produtoIndex][field] = value;
		if (field === 'id') {
			updatedOs.produtosSelecionados[produtoIndex].valor_venda = produto.valor_venda; // Atualizando o valor de venda
		}
		setOs(updatedOs);
	};

	const alterarPrecoProduto = (id, novoPreco) => {
		const updatedOs = { ...os };
		const produtoIndex = updatedOs.produtosSelecionados.findIndex((produto) => produto.id === id);
		updatedOs.produtosSelecionados[produtoIndex].valor_venda = novoPreco;
		setOs(updatedOs);
	};

	const handleRemoveProduto = (id) => {
		const updatedProdutosSelecionados = os.produtosSelecionados.filter((produto) => produto.id !== id);
		const updatedOs = { ...os, produtosSelecionados: updatedProdutosSelecionados };
		setOs(updatedOs);
	};

	const handleAddProduto = () => {
		// Lógica para adicionar um novo produto ao estado `os`
		const novoProduto = {
			id: "", // Defina o valor do ID do novo produto
			valor_venda: "", // Defina o valor do preço do novo produto
			quantidade: "", // Defina o valor da quantidade do novo produto
		};

		const updatedProdutosSelecionados = [...os.produtosSelecionados, novoProduto];
		const updatedOs = { ...os, produtosSelecionados: updatedProdutosSelecionados };
		setOs(updatedOs);
	};

	//servicos
	const handleServicoChange = (id, field, value, servico) => {
		console.log(id, field, value, servico)
		const updatedOs = { ...os };
		const servicoIndex = updatedOs.servicosSelecionados.findIndex((servico) => servico.id === id);
		updatedOs.servicosSelecionados[servicoIndex][field] = value;
		if (field === 'id') {
			updatedOs.servicosSelecionados[servicoIndex].valor = servico.valor; // Atualizando o valor de venda
		}
		setOs(updatedOs);
	};

	const handleRemoveServico = (id) => {
		const updatedServicosSelecionados = os.servicosSelecionados.filter((servico) => servico.id !== id);
		const updatedOs = { ...os, servicosSelecionados: updatedServicosSelecionados };
		setOs(updatedOs);
	};

	const handleAddServico = () => {
		// Lógica para adicionar um novo servico ao estado `os`
		const novoServico = {
			id: "", // Defina o valor do ID do novo servico
			valor: "", // Defina o valor do preço do novo servico
		};

		const updatedServicosSelecionados = [...os.servicosSelecionados, novoServico];
		const updatedOs = { ...os, servicosSelecionados: updatedServicosSelecionados };
		setOs(updatedOs);
	};

	const alterarPrecoServico = (id, novoPreco) => {
		const updatedOs = { ...os };
		const servicoIndex = updatedOs.servicosSelecionados.findIndex((servico) => servico.id === id);
		updatedOs.servicosSelecionados[servicoIndex].valor = novoPreco;
		setOs(updatedOs);
	};


	//veiculo adiciona no objeto "veiculoSelecionado" o id do veículo no campo 'id'
	const handleVeiculoChange = (id) => {
		const updatedOs = { ...os };
		const veiculo = os.veiculos.find((veiculo) => veiculo.id === id);
		console.log(veiculo)
		setOs((prevOs) => ({
			...prevOs,
			veiculoSelecionado: veiculo
		}));

		//limpar o objeto "peliculasSelecionadas" para não ter conflito de informações e inserir somente o id do veiculo
		updatedOs.peliculasSelecionadas = {};
		setOs((prevOs) => ({
			...prevOs,
			peliculasSelecionadas: updatedOs.peliculasSelecionadas
		}));

	};

	const handlePeliculaChange = (vidro, id, area) => { //vai receber o nome do vidro e o id da pelicula respectiva, deve inserir no objeto peliculasSelecionadas
		const updatedOs = { ...os };
		const aux = updatedOs.peliculas.find((pelicula) => pelicula.id === id).valor_venda * area;

		const valor_vidro = aux.toFixed(2);
		const novosValores = { id, area, valor_vidro };
		updatedOs.peliculasSelecionadas[vidro] = {
			...updatedOs.peliculasSelecionadas[vidro], // Mantém as propriedades existentes
			...novosValores, // Atualiza com as novas propriedades
		};
		setOs((prevOs) => ({
			...prevOs,
			peliculasSelecionadas: updatedOs.peliculasSelecionadas
		}));

	};

	const handlePeliculaPrecoChange = (vidro, valor) => { //vai receber o nome do vidro e o valor da pelicula respectiva, deve inserir no objeto peliculasSelecionadas
		const valorNumerico = parseFloat(valor.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.'));
		const updatedOs = { ...os };


		const valor_vidro = valor;
		updatedOs.peliculasSelecionadas[vidro].valor_vidro = valor_vidro;
		setOs((prevOs) => ({
			...prevOs,
			peliculasSelecionadas: updatedOs.peliculasSelecionadas
		}));
	};

	//função para calcular o valor dos produtos da OS
	const calcularValorProdutos = () => {
		const produtos = os.produtosSelecionados.map((produto) => {
			if (produto.id && produto.valor_venda && produto.quantidade) {
				const valorVenda = parseFloat(produto.valor_venda.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.'));
				// console.log(produto.desconto);

				if (produto.desconto != undefined && produto.desconto != null && produto.desconto != "") {
					const desconto = parseFloat(produto.desconto.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.'));
					return (valorVenda * produto.quantidade) - desconto;
				}

				return valorVenda * produto.quantidade;
			}
			return 0;
		});

		const valorProdutos = produtos.reduce((acc, cur) => acc + cur, 0).toFixed(2);
		return valorProdutos;
	};

	const calcularValorServicos = () => {
		const servicos = os.servicosSelecionados.map((servico) => {
			if (servico.id && servico.valor && servico.quantidade) {
				const valor = parseFloat(servico.valor.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.'));

				if (servico.desconto != undefined && servico.desconto != null && servico.desconto != "") {
					const desconto = parseFloat(servico.desconto.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.'));
					return (valor * servico.quantidade) - desconto;
				}

				return valor * servico.quantidade;
			}
			return 0;
		});

		const valorServicos = servicos.reduce((acc, cur) => acc + cur, 0).toFixed(2);
		return valorServicos;
	};


	const calcularValorPeliculas = () => {
		const peliculasSelecionadas = os.peliculasSelecionadas;
		let valorTotal = 0;

		if (peliculasSelecionadas && typeof peliculasSelecionadas === 'object') {
			for (const chave in peliculasSelecionadas) {
				if (peliculasSelecionadas.hasOwnProperty(chave)) {
					const item = peliculasSelecionadas[chave];
					if (item && typeof item === 'object' && item.valor_vidro) {
						if (item.valor_vidro[0] == "R") {
							valorTotal += parseFloat(item.valor_vidro.replace(/[^0-9.,]+/g, '').replace(',', '.'));
						} else {
							valorTotal += parseFloat(item.valor_vidro);
						}
					}
				}
			}
		}

		//console.log(valorTotal);
		return valorTotal;
	};


	const calcularValorTotal = () => {

		let acrescimo = 0; //setar para watch('acrescimo') somente se for numérico, nao nulo, nao indefinido e nao vazio
		if (watch('acrescimo') !== undefined && watch('acrescimo') !== null && watch('acrescimo') !== '') {
			acrescimo = parseFloat(watch('acrescimo'));
		}

		const valorProdutos = calcularValorProdutos();
		const valorServicos = calcularValorServicos();
		const valorPeliculas = calcularValorPeliculas();
		const valorTotal = parseFloat(valorProdutos) + parseFloat(valorServicos) + parseFloat(valorPeliculas) + acrescimo;

		// console.log(valorProdutos);

		return valorTotal.toFixed(2);
	};

	useEffect(() => {
		calcularValorTotal();
	}, [os.produtosSelecionados, os.servicosSelecionados, os.peliculasSelecionadas]);

	const [showModalConfirm, setShowModalConfirm] = useState(false);
	const [modalData, setModalData] = useState({});

	const [status, setStatus] = useState({ success: false, message: '' })


	const submitOS = async () => {
		const { informacoesBasicas, peliculasSelecionadas, produtosSelecionados, veiculoSelecionado, servicosSelecionados, produtos, servicos, clientes, funcionarios, peliculas } = os;

		window.scrollTo(0, 0);
		if (!os.informacoesBasicas.id_cliente) {
			setStatus({
				success: false,
				message: "Campos obrigatórios não preenchidos."
			})
			return;
		}
		//se tiver algum campo de quantidade nulo ou indefinido, deve enviar alerta e não permitir o envio da OS
		if (produtosSelecionados.some((produto) => !produto.quantidade) || servicosSelecionados.some((servico) => !servico.quantidade)) {
			//o alerta deve ser feito aqui e deve ter título
			alert("Preencha a quantidade de todos os produtos ou serviços selecionados");
			return;
		}

		const formData = new FormData();
		formData.append('informacoesBasicas', JSON.stringify(informacoesBasicas));
		formData.append('peliculasSelecionadas', JSON.stringify(peliculasSelecionadas));
		formData.append('produtosSelecionados', JSON.stringify(produtosSelecionados));
		formData.append('veiculoSelecionado', JSON.stringify(veiculoSelecionado));
		formData.append('servicosSelecionados', JSON.stringify(servicosSelecionados));
		formData.append('produtos', JSON.stringify(produtos));
		formData.append('servicos', JSON.stringify(servicos));
		formData.append('clientes', JSON.stringify(clientes));
		formData.append('funcionarios', JSON.stringify(funcionarios));
		formData.append('peliculas', JSON.stringify(peliculas));
		formData.append('valor_total', calcularValorTotal());
		formData.append('arquivo', informacoesBasicas.anexos);

		try {
			const response = await Api.post("NovaOS", formData, {
				headers: { 'Content-Type': 'multipart/form-data' }
			});
			informacoesBasicas.idOS = response.data.insertID
			console.log(response.data.insertID)
			setStatus({
				success: true,
				message: "OS cadastrada com sucesso."
			})
			


		} catch (error) {
			console.log(error.response.data.error == 'Quantidade não informada')
			setStatus({
				success: false,
				message: error.response.data.error == 'Quantidade não informada' ? "Preencha a quantidade das películas selecionadas" : "Erro ao cadastrar OS."
			})
			console.log(error);
			return;
		}

		// modal perguntando se deseja imprimir a OS
		const data = { //definindo somente os itens necessários do objeto os
			informacoesBasicas,
			peliculasSelecionadas,
			produtosSelecionados,
			veiculoSelecionado,
			servicosSelecionados,
			produtos,
			servicos,
			clientes,
			funcionarios,
			peliculas,
			valor_total: calcularValorTotal()
		};
		console.log(data);

		setModalData({ data: data });
		setShowModalConfirm(true);

	};

	const calculaValor_final = () => {
		const subtotal = calcularValorTotal();
		let desconto = 0;

		if (os.informacoesBasicas.desconto !== undefined && os.informacoesBasicas.desconto !== null && os.informacoesBasicas.desconto !== '') { //se tiver um desconto
			desconto = parseFloat(os.informacoesBasicas.desconto.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.'));
			return (subtotal - desconto).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
		}

		return subtotal.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
	}

	const handleQuantidadeVidro = (vidro, quantidade) => { //atualizar o vidro do campo ${vidro}_qtd pela quantidade recebida	
		const updatedOs = { ...os };
		updatedOs.veiculoSelecionado[`${vidro}_qtd`] = quantidade;
		//deve criar .qtd em updatedOs.peliculasSelecionadas.${vidro}.qtd e atualizar o valor
		updatedOs.peliculasSelecionadas[vidro].qtd = quantidade;
		console.log(updatedOs)
		setOs(updatedOs);
	}


	const handleClienteChange = (selectedOption) => {
		handleFieldChange("id_cliente", selectedOption.value);
	};

	const handleFuncionarioChange = (selectedOption) => {
		handleFieldChange("id_funcionario", selectedOption.value);
	};

	const handleReset = (a) => {
		//remove from os.peliculasSelecionadas the key a
		const updatedOs = { ...os };
		delete updatedOs.peliculasSelecionadas[a];
		setOs(updatedOs);
	};

	return (
		<div className='page-full'>
			<form className='bg-white'>

				<div className="header-page-full mb-4 cursor-pointer" onClick={() => window.history.back()} >
					<FontAwesomeIcon icon="fa-solid fa-arrow-left" />
				</div>
				<div className="pt-5 m-5">
					<div className="row d-flex justify-content-center align-items-top">
						<div className='col-md-1'>
							<span className="ant-avatar-circle nex-avatar default circle-icon">
								<FontAwesomeIcon icon="fa-solid fa-table-cells-large" />
							</span>
						</div>
						<div className="col-md-8 mb-5">
							<h1 className="h3 mb-3 fw-bold">Informações da OS</h1>
							<div className="mb-3">
								<div>
									{status.message && (
										<div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
											<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
											<div className="alert-icon">
												<i className="far fa-fw fa-bell"></i>
											</div>
											<div className="alert-message">
												{status.message}
											</div>
										</div>
									)}
									<div className="row">

										<div className="col-md-5 mb-3">
											<label className="form-label">Nome do Cliente*</label>
											<Select
												placeholder="Selecione"
												// options={os.clientes.map((cliente) => {
												// 	const nome = cliente.nome || cliente.nome_fantasia || "Nome não disponível";
												// 	const telefone = cliente.telefone || 'Indisponível'
												// 	return {
												// 		value: cliente.id,
												// 		label: `${nome} - ${telefone}`,
												// 		telefone: telefone,
												// 	};
												// })}
												options={os.clientes.map((cliente) => {
													const nome = cliente.nome || cliente.nome_fantasia || "Nome não disponível";
													const telefone = cliente.telefone || 'Indisponível'
													return {
														value: cliente.id,
														label: `${nome}`,
													};
												}).sort((a, b) => a.label.localeCompare(b.label))}
												onChange={handleClienteChange}
											/>
										</div>

										<div className="col-md-4 mb-3">
											<label className="form-label">Nome do Atendente</label>
											<Select
												placeholder="Selecione"
												options={os.funcionarios
													.map((funcionario) => {
														const nome = funcionario.apelido || funcionario.nome;
														return {
															value: funcionario.id,
															label: `${nome}`,
														};
													})
													.sort((a, b) => a.label.localeCompare(b.label)) // Ordenar alfabeticamente pelo rótulo
												}
												onChange={handleFuncionarioChange}
											/>

										</div>

										<div className="col-md-3 mb-3">
											<label className="form-label">Data de Início</label>
											<input
												className="form-control"
												type="date"
												name="data_inicio"
												defaultValue={os.informacoesBasicas.data_inicio}
												onChange={(e) => handleFieldChange("data_inicio", e.target.value)}
											/>
										</div>

									</div>

									<hr />
									<Pelicula
										os={os}
										handleVeiculoChange={handleVeiculoChange}
										handlePeliculaChange={handlePeliculaChange}
										handlePeliculaPrecoChange={handlePeliculaPrecoChange}
										handleFieldChange={handleFieldChange}
										handleQuantidadeVidro={handleQuantidadeVidro}
										handleReset={handleReset}
									/>

									<FormFields
										os={os}
										onFieldChange={handleFieldChange}

										handleProdutoChange={handleProdutoChange}
										handleRemoveProduto={handleRemoveProduto}
										handleAddProduto={handleAddProduto}
										alterarPrecoProduto={alterarPrecoProduto}

										handleServicoChange={handleServicoChange}
										handleRemoveServico={handleRemoveServico}
										handleAddServico={handleAddServico}
										alterarPrecoServico={alterarPrecoServico}

									/>
									<hr />

									<div className="col-md-12 mb-3">
										<label className="form-label">Observação</label>
										<TextareaAutosize
											className="form-control"
											name="observacao"
											onChange={(e) => handleFieldChange("observacao", e.target.value)}
											value={os.informacoesBasicas.observacao}
											minRows={1}
											maxRows={7}
										/>
									</div>

									<hr />

									<div className="row justify-content-end">
										<div className="col-md-3 mb-3 mt-4">
											<label>Desconto</label>
											<div className='input-group'>
												<CurrencyInput
													defaultValue={""}
													onChange={(event, value) =>
														handleFieldChange('desconto', event)}
												/>
											</div>
										</div>
										<div className="col-md-3 mb-3 mt-4">
											<label>Acréscimo</label>
											<div className='input-group'>
												<CurrencyInput
													defaultValue={""}
													onChange={(event, value) => {
														setValue('acrescimo', event.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.'));
														handleFieldChange('acrescimo', event.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.'));
														console.log(event.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.'));
													}}
												/>
											</div>
										</div>
										<div className="col-md-4 mb-3 mt-4">
											<label>Subtotal</label>
											<div className='input-group'>
												<span className="input-group-text">R$</span>
												<input
													className="form-control"
													type="text"
													value={Number(calcularValorTotal()).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', currencyDisplay: 'narrowSymbol' }).replace("R$", "")}
													readOnly
												/>
											</div>
										</div>
									</div>
									<div className="row justify-content-end">
										<div className="col-md-4 mb-3">
											<label><b>Total</b></label>
											<div className='input-group'>
												<span className="input-group-text">R$</span>
												<input
													className="form-control"
													type="text"
													value={calculaValor_final()}
													readOnly
												/>
											</div>
										</div>
									</div>

								</div>
							</div>
						</div>

					</div>
				</div>

				<div className="footer-page-full">
					<div className="footer-client">
						<button type="button" className="btn btn-light button-large fw-bold" onClick={() => submitOS()}> CADASTRAR OS </button>
						<button type="button" className="btn text-white button-large fw-bold" onClick={() => window.history.back()}> CANCELAR </button>
					</div>
				</div>

				<Modal centered show={showModalConfirm} onHide={() => { setShowModalConfirm(false); { window.location.href = "/listar-os" } }}>
					<Modal.Header closeButton>
						<Modal.Title>Confirmação</Modal.Title>
					</Modal.Header>
					<Modal.Body>Deseja imprimir a OS?</Modal.Body>
					<Modal.Footer>
						<button className="btn btn-primary" onClick={() => { Certificado(modalData.data); setShowModalConfirm(false); window.location.href = "/listar-os" }}>Confirmar</button>
						<button className="btn btn-secondary" onClick={() => { setShowModalConfirm(false); window.location.href = "/listar-os" }}>Cancelar</button>
					</Modal.Footer>
				</Modal>
			</form>
		</div>
	);
}

export default NovaOS;
