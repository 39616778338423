import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css'; // importe o tema que deseja usar
import { Portuguese } from 'flatpickr/dist/l10n/pt.js';
import { Link } from 'react-router-dom';
import Table from "../Components/Table"
import Api from '../Api';

import { Modal } from 'react-bootstrap';
import { TextareaAutosize } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { yellow } from '@mui/material/colors';
import { utcToZonedTime, } from 'date-fns-tz';
import { parseISO, format, set, isValid } from 'date-fns';
import printAgendamento from '../Components/pdf-agendamento';
import { tr } from 'date-fns/locale';




const Home = () => {


    const date = new Date(Date.now() - 5 * 24 * 60 * 60 * 1000);
    const defaultDate = date.getUTCFullYear() + "-" + (date.getUTCMonth() + 1) + "-" + date.getUTCDate();

    const [dateValue, setDateValue] = useState();
    const [dateTouched, setDateTouched] = useState(false);

    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [modalData, setModalData] = useState({});
    const [modalMode, setModalMode] = useState('');

    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);

    const [maxId, setMaxId] = useState(0);

    const { token, nivel, nome } = {
        token: localStorage.getItem('token'),
        nivel: localStorage.getItem('nivel'),
        nome: localStorage.getItem('nome')
    };


    const columns = React.useMemo(
        () => [
            {
                Header: '',
                isVisible: false,
                hideHeader: false,
                id: 'id',
                columns: [
                    {
                        Header: "#",
                        accessor: row => <Link to={`/editar-fornecedor/${row.id}`}>{row.id ?? '-'}</Link>
                    },
                    {
                        Header: "Nome",
                        accessor: row => 'Lorem Ipsum'
                    },
                    {
                        Header: "Data",
                        accessor: row => <Link to={`/editar-fornecedor/${row.id}`}>{row.telefone || '-'}</Link>
                    }
                ]
            }
        ],
        []
    );

    const formatDate = (isoDate) => {
        const dateObj = new Date(isoDate);
        return format(dateObj, 'dd/MM/yyyy - HH:mm');
    };

    const deleteAgendamento = async (data) => {
        console.log(data)
        try {
            const response = await Api.delete(`DeletarAgendamento/${data}`);

            if (response.data.success === true) {
                // remover o agendamento da lista local também
                const newAgendamentos = Agendamentos.filter(agendamento => agendamento.id !== data);
                setAgendamentos(newAgendamentos);
                setAgendamentosFiltered(newAgendamentos);
                setDateTouched(false);
                setDateValue(null);
            }
        } catch (error) {
            console.log(error)
        }

    };

    const [Fornecedores, setFornecedores] = useState([]);
    const [Agendamentos, setAgendamentos] = useState([]);
    const [AgendamentosFiltered, setAgendamentosFiltered] = useState([]);
    const [cardsInfo, setCardsInfo] = useState({});
    const [orcamentosAbertos, setOrcamentosAbertos] = useState([]);
    const [ultimasVendas, setUltimasVendas] = useState([]);
    const [produtos, setProdutos] = useState([]);

    const columnsAgendamento = React.useMemo(
        () => [
            {
                Header: '',
                isVisible: false,
                hideHeader: false,
                id: 'id',
                columns: [
                    // {
                    //     Header: "#",
                    //     accessor: row => <Link to={`/editar-fornecedor/${row.id}`}>{row.id ?? '-'}</Link>
                    // },
                    {
                        Header: "Título",
                        accessor: "titulo",
                        Cell: ({ cell: { value }, row: { original } }) => (
                            <div
                                onClick={() => {
                                    const brazilTimeZone = 'America/Sao_Paulo'; // Fuso horário do Brasil
                                    console.log(original.data)


                                    const originalDate = parseISO(original.data);

                                    const formattedTime = format(originalDate, 'HH:mm', { timeZone: brazilTimeZone });

                                    setModalData({
                                        id: original.id,
                                        title: original.titulo,
                                        data: originalDate, // Salva o objeto de data do JavaScript
                                        horario: formattedTime, // Horário formatado no padrão "HH:mm"
                                        descricao: original.descricao,
                                    });
                                    setModalMode('editar');
                                    setShowModalConfirm(true);
                                }}
                                style={{ cursor: 'pointer' }}
                            >
                                {value || "-"}
                            </div >
                        ),
                    },
                    {
                        Header: "Data",
                        accessor: row => (
                            <div
                                onClick={() => {
                                    const brazilTimeZone = 'America/Sao_Paulo'; // Fuso horário do Brasil
                                    console.log(row.data)


                                    const originalDate = parseISO(row.data);

                                    const formattedTime = format(originalDate, 'HH:mm', { timeZone: brazilTimeZone });

                                    setModalData({
                                        id: row.id,
                                        title: row.titulo,
                                        data: originalDate, // Salva o objeto de data do JavaScript
                                        horario: formattedTime, // Horário formatado no padrão "HH:mm"
                                        descricao: row.descricao,
                                    });
                                    setModalMode('editar');
                                    setShowModalConfirm(true);
                                }}
                                style={{ cursor: 'pointer' }}
                            >

                                {row.data ? formatDate(row.data) : '-'}
                            </div >
                        )
                    },
                    {
                        Header: "Descrição",
                        accessor: row => (
                            <div
                                onClick={() => {
                                    const brazilTimeZone = 'America/Sao_Paulo'; // Fuso horário do Brasil
                                    console.log(row.data)


                                    const originalDate = parseISO(row.data);

                                    const formattedTime = format(originalDate, 'HH:mm', { timeZone: brazilTimeZone });

                                    setModalData({
                                        id: row.id,
                                        title: row.titulo,
                                        data: originalDate, // Salva o objeto de data do JavaScript
                                        horario: formattedTime, // Horário formatado no padrão "HH:mm"
                                        descricao: row.descricao,
                                    });
                                    setModalMode('editar');
                                    setShowModalConfirm(true);
                                }}
                                style={{ cursor: 'pointer' }}
                            >

                                {row.descricao ? row.descricao.slice(0, 30) : '-'}
                                {row.descricao && row.descricao.length > 30 ? '...' : ''}
                            </div >
                        ),
                    },
                    {
                        Header: " ",
                        accessor: row =>
                            <>
                                <Link className="fs-4 me-3" onClick={() => { deleteAgendamento(row.id); }}>
                                    <i className="bi bi-trash3-fill"></i>
                                </Link>
                                <Link className="fs-4" onClick={() => { console.log(row); printAgendamento(row) }}>
                                    <i className="bi bi-printer-fill"></i>
                                </Link>

                            </>
                    }
                ]
            }
        ],
        [Agendamentos, AgendamentosFiltered]
    );

    const columnsOrcamentos = React.useMemo(
        () => [
            {
                Header: '',
                isVisible: false,
                hideHeader: false,
                id: 'id',
                columns: [
                    {
                        Header: "#",
                        accessor: row => <Link to={`/editar-os/${row.idOS}`}>{row.idOS ?? '-'}</Link>
                    },
                    {
                        Header: "Cliente",
                        accessor: row => <Link to={`/editar-os/${row.idOS}`}>{row.nomeCliente || '-'}</Link>
                    },
                    {
                        Header: "Funcionario",
                        accessor: row => <Link to={`/editar-os/${row.idOS}`}>{row.nomeFuncionario || '-'}</Link>
                    },
                    {
                        Header: "Data",
                        accessor: row => <Link to={`/editar-os/${row.idOS}`}>{row.data_inicio || '-'}</Link>
                    },
                    {
                        Header: "Valor final",
                        accessor: row => <Link to={`/editar-os/${row.idOS}`}>{row.valor_final || '-'}</Link>
                    }
                ]
            }
        ],
        [orcamentosAbertos]
    );

    const columnsVendas = React.useMemo(
        () => [
            {
                Header: '',
                isVisible: false,
                hideHeader: false,
                id: 'id',
                columns: [
                    {
                        Header: "Nome",
                        accessor: "nome",
                    },
                    {
                        Header: "Tipo",
                        accessor: "tipo",
                    },
                    {
                        Header: "Estoque",
                        accessor: "estoque",
                        Cell: ({ value, row }) => (
                            <span style={{ color: (parseFloat(value) < parseFloat(row.original.estoque_min) || parseFloat(value) < 0) ? 'red' : 'black' }}>
                                {value}
                            </span>
                        ),
                    },
                ]
            }
        ],
        [produtos]
    );




    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get('ListarFornecedores');
                setFornecedores(response.data);
            } catch (error) {
                console.error(error);
            }

            try {
                const response = await Api.get('orcamentosAbertos');
                setOrcamentosAbertos(response.data.data);
                console.log(response.data.data);
            } catch (error) {
                console.error(error);
            }

            try {
                const response = await Api.get('ultimasVendas');
                setUltimasVendas(response.data.data);
                console.log(response.data.data);
            } catch (error) {
                console.error(error);
            }

            try {
                const response = await Api.get('ListarAgendamentos');
                setAgendamentos(response.data.data);
                setAgendamentosFiltered(response.data.data);
                console.log(response.data.data);

                //encontrar o último id
                const maxId = Math.max.apply(Math, response.data.data.map(function (o) { return o.id; }))
                setMaxId(maxId);
            } catch (error) {
                console.error(error);
            }

            try {
                const response = await Api.get('HomeCards');
                setCardsInfo(response.data.data);
            } catch (error) {
                console.error(error);
            }

            try {
                const response = await Api.get('ListarProdutos');

                //filtrar o response.data somente para os produtos que o estoque é menor que o estoque minimo ou no maximo 15% maior. remover todos que não tem estoque_min
                const produtosFiltrados = response.data.filter((produto) => {
                    if (produto.estoque_min && produto.estoque) {
                        return (produto.estoque < produto.estoque_min || produto.estoque > (produto.estoque_min * 1.15));
                    }
                });
                setProdutos(produtosFiltrados);
                console.log(response.data);
            } catch (error) {
                console.error(error);
            }



        }

        fetchData();
    }, []);

    useEffect(() => {
        setDateValue(new Date());
        filtrarAgendamento(new Date());
        setDateTouched(true);
    }, [Agendamentos]);

    const NovoAgendamento = async (data) => {
        try {
            console.log(data)
            // Convertendo a data para o formato correto
            const timeZone = 'America/Sao_Paulo';
            const utcDate = utcToZonedTime(data.data, timeZone);

            const formattedDateTime = format(utcDate, 'yyyy-MM-dd HH:mm:ss', { timeZone }); //esse vai pro backend
            console.log("formattedDateTime", formattedDateTime);

            let horario_local = formattedDateTime.replace(' ', 'T').replace('00:00:00', data.horario + ':00.000Z');
            //deve somar 3 horas
            horario_local = new Date(horario_local);
            horario_local.setHours(horario_local.getHours() + 3);
            horario_local = horario_local.toISOString();

            console.log("horario_local?", horario_local);

            const agendamentoData = {
                ...data,
                data: formattedDateTime,
            };



            const response = await Api.post('NovoAgendamento', JSON.stringify(agendamentoData), {
                headers: { 'Content-Type': 'application/json' } // Definindo o formato como JSON para o backend receber corretamente
            });
            setMaxId(response.data.insertedId);

            setAgendamentos([...Agendamentos, { ...agendamentoData, id: response.data.insertedId, titulo: agendamentoData.title, descricao: agendamentoData.descricao, data: horario_local }]);
            setAgendamentosFiltered([...Agendamentos, { ...agendamentoData, id: response.data.insertedId, titulo: agendamentoData.title, descricao: agendamentoData.descricao, data: horario_local }]);

            setDateTouched(false); setDateValue(null); //resetando o calendário


            console.log('Agendamento salvo com sucesso!');
        } catch (error) {
            console.log(error);
        }
    };

    const filtrarAgendamento = (data) => {
        // Cria uma nova data com apenas informações de dia, mês e ano
        const dataParaFiltrar = new Date(data.getFullYear(), data.getMonth(), data.getDate());

        // Filtrar os agendamentos cujo dia da data seja igual ao dia da data fornecida
        const agendamentosFiltrados = Agendamentos.filter(agendamento => {
            const agendamentoDate = new Date(agendamento.data);
            const agendamentoDataParaFiltrar = new Date(agendamentoDate.getFullYear(), agendamentoDate.getMonth(), agendamentoDate.getDate());
            return agendamentoDataParaFiltrar.getTime() === dataParaFiltrar.getTime();
        });

        // Atualiza o estado Agendamentos com os agendamentos filtrados
        console.log("filtrado", agendamentosFiltrados)
        setAgendamentosFiltered(agendamentosFiltrados.sort((a, b) => a.horario > b.horario ? 1 : -1));
    };


    const EditarAgendamento = async (data) => {
        console.log(data);
        try {
            // Convertendo a data para o formato correto
            const timeZone = 'America/Sao_Paulo';
            const utcDate = utcToZonedTime(data.data, timeZone);
            const formattedDateTime = format(utcDate, 'yyyy-MM-dd HH:mm:ss', { timeZone });

            const agendamentoData = {
                ...data,
                data: formattedDateTime,
            };

            // console.log(formattedDateTime)
            let horario_local = formattedDateTime.split(' ')[0] + ('T' + data.horario + ':00.000Z');
            //deve somar 3 horas
            horario_local = new Date(horario_local);
            horario_local.setHours(horario_local.getHours() + 3);
            horario_local = horario_local.toISOString();

            console.log("horario_local?", horario_local);

            //atualiza o objeto local para atualizar a tabela na UI
            console.log(agendamentoData.data)

            const response = await Api.put('EditarAgendamento', JSON.stringify(agendamentoData), {
                headers: { 'Content-Type': 'application/json' } // Definindo o formato como JSON para o backend receber corretamente
            });

            setAgendamentos([...Agendamentos.filter(agendamento => agendamento.id !== data.id), { ...agendamentoData, titulo: agendamentoData.title, descricao: agendamentoData.descricao, data: horario_local }]);
            setAgendamentosFiltered([...Agendamentos.filter(agendamento => agendamento.id !== data.id), { ...agendamentoData, titulo: agendamentoData.title, descricao: agendamentoData.descricao, data: horario_local }]);
            console.log('Agendamento salvo com sucesso!');
        } catch (error) {
            console.log(error);
        }
    };


    return (
        <div className="container-fluid p-0">
            <section>
                <div className='row'>
                    <div className="col-xl-12 col-xxl-12 d-flex">
                        <div className="w-100">
                            <div className="row">
                                {nivel == 1 ? (
                                    <>
                                        <div className="col-sm-6 col-lg-6 col-md-6 col-xl-3">
                                            <div className="card">
                                                <div className="card-body pb-0">
                                                    <div className="row">
                                                        <div className="col mt-0">
                                                            <h5 className="card-title">Vendas Hoje</h5>
                                                        </div>

                                                        <div className="col-auto">
                                                            <div className="stat text-primary">
                                                                <FontAwesomeIcon icon="fa-solid fa-sack-dollar" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h1 className="mt-1 mb-3">{cardsInfo.valorVendasHoje}</h1>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-lg-6 col-md-6 col-xl-3">
                                            <div className="card">
                                                <div className="card-body pb-0">
                                                    <div className="row">
                                                        <div className="col mt-0">
                                                            <h5 className="card-title">Recebimentos de hoje</h5>
                                                        </div>

                                                        <div className="col-auto">
                                                            <div className="stat text-primary">
                                                                <FontAwesomeIcon icon="fa-solid fa-bag-shopping" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h1 className="mt-1 mb-3">{cardsInfo.recebimentosHoje}</h1>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-lg-6 col-md-6 col-xl-3">
                                            <div className="card">
                                                <div className="card-body pb-0">
                                                    <div className="row">
                                                        <div className="col mt-0">
                                                            <h5 className="card-title">OS Abertas</h5>
                                                        </div>

                                                        <div className="col-auto">
                                                            <div className="stat text-primary">
                                                                <FontAwesomeIcon icon="fa-solid fa-clipboard-list" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h1 className="mt-1 mb-3">{cardsInfo.qtdOSAbertas}</h1>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-lg-6 col-md-6 col-xl-3">
                                            <div className="card">
                                                <div className="card-body pb-0">
                                                    <div className="row">
                                                        <div className="col mt-0">
                                                            <h5 className="card-title">Agendamentos</h5>
                                                        </div>

                                                        <div className="col-auto">
                                                            <div className="stat text-primary">
                                                                <FontAwesomeIcon icon="fa-solid fa-calendar-days" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h1 className="mt-1 mb-3">{cardsInfo.qtdAgendamentos}</h1>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="col-sm-6 col-lg-6 col-md-6 col-xl-6">
                                            <div className="card">
                                                <div className="card-body pb-0">
                                                    <div className="row">
                                                        <div className="col mt-0">
                                                            <h5 className="card-title">OS Abertas</h5>
                                                        </div>

                                                        <div className="col-auto">
                                                            <div className="stat text-primary">
                                                                <FontAwesomeIcon icon="fa-solid fa-clipboard-list" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h1 className="mt-1 mb-3">{cardsInfo.qtdOSAbertas}</h1>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-lg-6 col-md-6 col-xl-6">
                                            <div className="card">
                                                <div className="card-body pb-0">
                                                    <div className="row">
                                                        <div className="col mt-0">
                                                            <h5 className="card-title">Agendamentos futuros</h5>
                                                        </div>

                                                        <div className="col-auto">
                                                            <div className="stat text-primary">
                                                                <FontAwesomeIcon icon="fa-solid fa-calendar-days" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h1 className="mt-1 mb-3">{cardsInfo.qtdAgendamentos}</h1>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}

                            </div>
                        </div>
                    </div>

                    <div className='col-12'>
                        <div className="agendamentos-section">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="card-title mb-0">Agendamentos</h5>
                                <button 
                                    className="btn btn-sm btn-primary"
                                    onClick={() => { 
                                        setModalMode('nova'); 
                                        setShowModalConfirm(true); 
                                    }}
                                >
                                    <FontAwesomeIcon icon="plus" className="me-2" />
                                    Novo Agendamento
                                </button>
                            </div>
                            
                            <div className="agendamentos-container">
                                <div className="calendar-container">
                                    <Flatpickr
                                        value={dateValue}
                                        options={{
                                            locale: Portuguese,
                                            inline: true,
                                            prevArrow: "<span class='fas fa-chevron-left'></span>",
                                            nextArrow: "<span class='fas fa-chevron-right'></span>"
                                        }}
                                        onChange={date => {
                                            setDateValue(date[0]);
                                            filtrarAgendamento(date[0]);
                                            setDateTouched(true)
                                        }}
                                    />
                                </div>

                                <div className="table-container">
                                    {AgendamentosFiltered && AgendamentosFiltered.length > 0 ? (
                                        <Table 
                                            columns={columnsAgendamento} 
                                            data={AgendamentosFiltered} 
                                            search={false}
                                            lenght={false}
                                            info={false}
                                            responsive={true}
                                        />
                                    ) : (
                                        <div className="empty-state">
                                            <FontAwesomeIcon 
                                                icon="calendar-alt" 
                                                className="empty-state-icon"
                                            />
                                            <h4 className="empty-state-text">
                                                Nenhum agendamento encontrado
                                            </h4>
                                            {dateTouched ? (
                                                <p className="empty-state-subtext">
                                                    Não há agendamentos para a data selecionada
                                                </p>
                                            ) : (
                                                <p className="empty-state-subtext">
                                                    Selecione uma data no calendário para visualizar os agendamentos
                                                </p>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* pedido para tirar */}
                    {/* <div className='col-xl-12 col-xxl-12 d-none d-md-block'>
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">Orçamentos Abertos</h5>
                                <h6 className="card-subtitle text-muted">Listagem dos últimos orçamentos que estão em aberto.</h6>
                            </div>
                            <div className="card-body">
                                <Table columns={columnsOrcamentos} data={orcamentosAbertos} search='false' lenght='false' />
                            </div>

                        </div>
                    </div> 

                    <div className='col-xl-12 col-xxl-12 d-none d-md-block'>
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">Últimas Vendas</h5>
                                <h6 className="card-subtitle text-muted">Listagem das últimas vendas realizadas.</h6>
                            </div>
                            <div className="card-body">
                                <Table columns={columnsVendas} data={ultimasVendas} search='false' lenght='false' />
                            </div>

                        </div>
                    </div> */}

                    <div className='col-xl-12 col-xxl-12 d-none d-md-block'>
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">ESTOQUE</h5>
                                <h6 className="card-subtitle text-muted">Listagem de estoques próximos do limite</h6>
                            </div>
                            <div className="card-body">
                                <Table columns={columnsVendas} data={produtos} search='false' lenght='false' /> 
                                {/* //a tabela mostra os estoques que estão até 15% acima do estoque minimo e destaca os que abaixaram do estoque minimo */}
                            </div>

                        </div>
                    </div>



                </div>


            </section >


            <Modal centered show={showModalConfirm} onHide={() => { setShowModalConfirm(false); setSelectedDate(null); setSelectedTime(null); setModalMode(''); setModalData({}); }}>
                <Modal.Header closeButton>
                    {modalMode === 'nova' ? (
                        <Modal.Title>Novo Agendamento</Modal.Title>
                    ) : (
                        <Modal.Title>Editar Agendamento</Modal.Title>
                    )}
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-6">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Título"
                                style={{ fontWeight: 'bold' }}
                                defaultValue={modalData.title}
                                onChange={e => setModalData({ ...modalData, title: e.target.value })}
                            />
                        </div>
                        <div className="col-3">
                            <DatePicker
                                selected={modalData.data || null}
                                onChange={date => {
                                    setModalData({ ...modalData, data: date });
                                    setSelectedDate(date);
                                }}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Selecione a data"
                                className="form-control"
                            />
                        </div>
                        <div className="col-3">
                            <input
                                type="time"
                                defaultValue={modalData.horario || ''}
                                onChange={time => {
                                    setModalData({ ...modalData, horario: time.target.value });
                                    setSelectedTime(time);
                                }}
                                className="form-control"
                            />

                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <TextareaAutosize
                                type="text"
                                className="form-control"
                                placeholder="Descrição"
                                defaultValue={modalData.descricao}
                                maxLength={1000}
                                onChange={e => setModalData({ ...modalData, descricao: e.target.value })}
                            />
                        </div>
                    </div>


                </Modal.Body>
                <Modal.Footer>
                    {modalMode == "nova" ? (
                        <button className="btn btn-primary" onClick={() => { NovoAgendamento(modalData); setModalData({}); setShowModalConfirm(false); setSelectedDate(null); setSelectedTime(null); setModalMode('') }}
                            disabled={modalData.title && modalData.data && modalData.horario && modalData.descricao ? false : true}
                        >Confirmar</button>
                    ) : (
                        <button className="btn btn-primary" onClick={() => { EditarAgendamento(modalData); setModalData({}); setShowModalConfirm(false); setSelectedDate(null); setSelectedTime(null); setModalMode('') }}>Editar</button>
                    )}
                    <button className="btn btn-secondary" onClick={() => { console.log(modalData); setModalData({}); setShowModalConfirm(false); setSelectedDate(null); setSelectedTime(null); setModalMode('') }}>Cancelar</button>
                </Modal.Footer>
            </Modal>




        </div >


    );

}

export default Home;