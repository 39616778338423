import React, { useState } from 'react';
import { IMaskInput } from 'react-imask';
import Api from '../../Api';
import './Login.css';
import logoImage from '../../assets/images/logo-black.png'; // Adicione o logo da sua empresa
import loginIllustration from '../../assets/images/login-illustration.svg'; // Adicione uma ilustração

const LoginPage = () => {
	const [cpf, setCPF] = useState('');
	const [password, setPassword] = useState('');
	const [status, setStatus] = useState({ success: false, message: '' });
	const [loading, setLoading] = useState(false);

	const handleLogin = async (e) => {
		e.preventDefault();
		setLoading(true);

		const data = {
			cpf: cpf,
			senha: password
		};

		try {
			const response = await Api.post('Login', JSON.stringify(data), {
				headers: { 'Content-Type': 'application/json' }
			});

			if (response.data.success === true) {
				setStatus({ success: true, message: 'Login realizado com sucesso!' });
				localStorage.setItem('token', response.data.token);
				localStorage.setItem('cpf', cpf);
				localStorage.setItem('nivel', response.data.nivel);
				localStorage.setItem('nome', response.data.nome);
				
				// Adiciona uma pequena delay para mostrar a mensagem de sucesso
				setTimeout(() => {
					window.location.href = '/home';
				}, 1000);
			}
		} catch (error) {
			setStatus({ 
				success: false, 
				message: error.response?.data?.error || 'Erro ao realizar login. Tente novamente.'
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="login-container">
			<div className="login-content">
				<div className="login-form-container">
					<div className="text-center">
						<img src={logoImage} alt="Logo" className="login-logo" />
						<h1 className="login-title">Bem-vindo de volta!</h1>
					</div>

					{status.message && (
						<div className={`alert ${status.success ? 'alert-success' : 'alert-danger'}`}>
							<i className={`fas ${status.success ? 'fa-check-circle' : 'fa-exclamation-circle'}`}></i>
							<span>{status.message}</span>
						</div>
					)}

					<form onSubmit={handleLogin}>
						<div className="form-floating">
							<IMaskInput
								mask="000.000.000-00"
								type="text"
								id="cpf"
								className="form-control"
								placeholder=" "
								value={cpf}
								onAccept={(value) => setCPF(value)}
								required
							/>
							<label htmlFor="cpf">CPF</label>
						</div>

						<div className="form-floating">
							<input
								type="password"
								id="password"
								className="form-control"
								placeholder=" "
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								required
							/>
							<label htmlFor="password">Senha</label>
						</div>

						<button 
							type="submit" 
							className="login-button"
							disabled={loading}
						>
							{loading ? (
								<span>
									<i className="fas fa-spinner fa-spin me-2"></i>
									Entrando...
								</span>
							) : 'Entrar'}
						</button>
					</form>
				</div>

				<div className="login-illustration">
					<img 
						src={loginIllustration} 
						alt="Login illustration" 
						style={{ maxWidth: '100%', height: 'auto' }}
					/>
				</div>
			</div>
		</div>
	);
};

export default LoginPage;
