import React, { useState, useRef, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Api from '../../Api';
import { Modal } from 'react-bootstrap';

import CurrencyInput from '../../Components/CurrencyInput';
import TextareaAutosize from "react-textarea-autosize";
import { set, useForm } from 'react-hook-form';
import Select from 'react-select';
import { IMaskInput } from "react-imask";
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Certificado from './pdf';
import EditarClienteOS from '../Clientes/EditarClienteOS';


function NovaArquitetura() {
    const { id } = useParams(); // Obtém o id do cliente a partir da URL

    const { register, watch, handleSubmit, formState: { errors }, reset, setValue } = useForm()
    const [status, setStatus] = useState({ success: false, message: '' })
    const [endereco, setEndereco] = useState({ cep: '', endereco: '', bairro: '', cidade: '', complemento: '' })

    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [modalData, setModalData] = useState({});

    const [buttonClicked, setButtonClicked] = useState(false);
    const [isRequestSuccessful, setIsRequestSuccessful] = useState(false);

    const [showModalEdicaoCliente, setShowModalEdicaoCliente] = useState(false);

    const handlefecharmodal = () => {
        console.log("hdnujsaidujna")
        setShowModalConfirm(false);
    }

    const handleButtonClick = async () => {
        setButtonClicked(true);
        try {
            const response = await Api.post("EnviarEmail", modalData, {
                headers: { 'Content-Type': 'application/json' }
            });

            if (response.data.success) {
                setIsRequestSuccessful(true);
            }
            console.log('Email enviado: ', response);

        } catch (error) {
            setIsRequestSuccessful(false);
        } finally {
            // Reset the button state after the request is complete
            setTimeout(() => {
                setButtonClicked(false);
                setIsRequestSuccessful(false);
            }, 3000); // Reset the button state after 5 seconds (adjust as needed)
        }
    };


    const [NovoClienteAdicionado, setNovoClienteAdicionado] = useState(false);

    function handleKeyUp(e) {
        e.target.value = e.target.value.toUpperCase();
    }
    const [os, setOs] = useState({
        vidros: [],
    });

    const telefone = useRef(null);
    const celular = useRef(null);
    const contato = useRef(null);
    const cep = useRef(null);

    const handleTelefoneChange = (event) => {
        setValue('telefone', event.target.value)
    }
    const handleCelularChange = (event) => {
        setValue('celular', event.target.value)
    }
    const handleContatoChange = (event) => {
        setValue('contato', event.target.value)
    }
    const handleTelefoneFocus = (e) => {
        const input = telefone.current;
        if (input) {
            input.setSelectionRange(4, 4);
        }
    };
    const handleCelularFocus = (e) => {
        const input = celular.current;
        if (input) {
            input.setSelectionRange(4, 4);
        }
    };
    const handleContatoFocus = (e) => {
        const input = contato.current;
        if (input) {
            input.setSelectionRange(4, 4);
        }
    };
    const handleCepChange = async (event) => {
        const cepValue = event.target.value.replace(/\D/g, '')
        if (cepValue.length !== 8) {
            return;
        }

        try {
            const response = await axios.get(`https://viacep.com.br/ws/${cepValue}/json/`)
            setEndereco({
                cep: response.data.cep,
                endereco: response.data.logradouro,
                bairro: response.data.bairro,
                cidade: response.data.localidade
            });
            // Atualiza os valores
            setValue('cidade', response.data.localidade);
            setValue('bairro', response.data.bairro);
            setValue('endereco', response.data.logradouro);
        } catch (error) {
            console.log(error)
        }

    };
    const handleAddVidro = () => {
        const novoVidro = {
            id: Date.now(), // Usaremos um timestamp como ID único
            altura: '',
            largura: '',
            quantidade: '',
            local_aplicacao: '',
            id_pelicula: '',
        };

        const updatedVidros = [...os.vidros, novoVidro];
        setOs({ ...os, vidros: updatedVidros });
    };

    const handleRemoveVidro = (id) => {
        const updatedVidros = os.vidros.filter((vidro) => vidro.id !== id);
        setOs({ ...os, vidros: updatedVidros });
    };

    const handleVidroChange = (id, fieldName, value) => {
        const updatedVidros = os.vidros.map((vidro) => {
            if (vidro.id === id) {
                return {
                    ...vidro,
                    [fieldName]: value,
                };
            }
            return vidro;
        });

        setOs({ ...os, vidros: updatedVidros });
    };

    const calculaSubtotalVidro = (idVidro) => {
        // Verifique se o vidro com o ID especificado existe em os.peliculas
        const vidro = os.peliculas.find((vidro) => vidro.id == os.vidros.find((vidro) => vidro.id == idVidro).id_pelicula);

        if (vidro) {
            //o vidro.valor_venda deve multiplicar pela quantidade altura e largura do idVidro
            const subtotal = vidro.valor_venda * os.vidros.find((vidro) => vidro.id == idVidro).quantidade.replace(",", ".") * os.vidros.find((vidro) => vidro.id == idVidro).altura.replace(",", ".") * os.vidros.find((vidro) => vidro.id == idVidro).largura.replace(",", ".");

            return subtotal.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
        }

        return 0;
    };

    const buildaOS = (informacoesBasicas) => {
        //campos basicos
        handleFieldChange("id_cliente", informacoesBasicas.id_cliente);
        handleFieldChange("id_funcionario", informacoesBasicas.id_funcionario);
        const inicio = informacoesBasicas.data_inicio ? informacoesBasicas.data_inicio.split("T")[0] : null;
        handleFieldChange("data_inicio", inicio);
        handleFieldChange("data_fim", informacoesBasicas.data_fim);
        handleFieldChange("valor_total", informacoesBasicas.valor_total);
        handleFieldChange("desconto", informacoesBasicas.desconto);
        handleFieldChange("acrescimo", informacoesBasicas.acrescimo);
        handleFieldChange("observacao", informacoesBasicas.observacao);


        if (informacoesBasicas.desconto !== null) {
            handleFieldChange("desconto", formatarValorMonetario(informacoesBasicas.desconto));
        }

        if (informacoesBasicas.acrescimo !== null) {
            handleFieldChange("acrescimo", formatarValorMonetario(informacoesBasicas.acrescimo));
            setValue('acrescimo', informacoesBasicas.acrescimo);
        }

    }
    const formatarValorMonetario = (valor) => {
        const valorNumerico = parseFloat(valor);

        if (isNaN(valorNumerico)) {
            return '';
        }

        const valorFormatado = valorNumerico.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });
        return valorFormatado;
    }

    // busca informação do cliente
    useEffect(() => {
        const fetchData = async () => {
            try {
                const clientesResponse = await Api.get("ListarClientes");
                setOs((prevOs) => ({
                    ...prevOs,
                    clientes: clientesResponse.data,
                }));
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, [showModalEdicaoCliente])

    useEffect(() => { //buscando informações de produtos , serviços e funcionários
        const fetchData = async () => {
            try {
                const response = await Api.get("ListarProdutos?modo=OS")
                const filter = await response.data.filter((produto) => produto.tipo === "material")
                setOs((prevOs) => ({
                    ...prevOs,
                    peliculas: filter,
                }));
            } catch (error) {
                console.log(error);
            }

            try {
                const funcionariosResponse = await Api.get("ListarFuncionarios");
                setOs((prevOs) => ({
                    ...prevOs,
                    funcionarios: funcionariosResponse.data,
                }));
            } catch (error) {
                console.log(error);
            }

            try {
                const OS_buscada = await Api.get(`BuscarOS?id=${id}`)
                buildaOS(OS_buscada.data.data[0])

            } catch (error) {
                console.log(error)
            }

            try {
                const vidrosResponse = await Api.get(`BuscarVidrosOS?id=${id}`);
                const vidros = vidrosResponse.data.data.map((vidro, index) => {
                    return {
                        id: index,
                        altura: vidro.altura,
                        largura: vidro.largura,
                        quantidade: vidro.quantidade,
                        local_aplicacao: vidro.local_aplicacao,
                        id_pelicula: vidro.id_material,
                    }
                })
                setOs((prevOs) => ({
                    ...prevOs,
                    vidros: vidros,
                }));

            } catch (error) {
                console.log(error)
            }

            try {
                const aplicadoresResponse = await Api.get(`BuscarAplicador?id=${id}`);
                const aplicador = aplicadoresResponse.data.data[0].id_funcionario;
                handleFieldChange("aplicador", aplicador.split(','));

            } catch (error) {
                console.log(error)
            }
        }
        fetchData();
        console.log("os: ", os)

    }, []);



    //informacoes basicas
    const handleFieldChange = (fieldName, value) => { //interface para a classe OS
        console.log(fieldName, value)
        setOs((prevOs) => ({
            ...prevOs,
            informacoesBasicas: {
                ...prevOs.informacoesBasicas,
                [fieldName]: value,
            },
        }));
    };


    const handleClienteChange = (selectedOption) => {
        handleFieldChange("id_cliente", selectedOption.value);
    };

    const handleFuncionarioChange = (selectedOption) => {
        handleFieldChange("id_funcionario", selectedOption.value);
    };

    const submitOS = async () => {
        window.scrollTo(0, 0);

        //se tiver algum campo de quantidade nulo ou indefinido, deve enviar alerta e não permitir o envio da OS
        if (os.vidros.some((vidro) => !vidro.quantidade || vidro.quantidade === undefined || vidro.quantidade === null || vidro.quantidade === '')) {
            alert("Preencha a quantidade de todos os produtos ou serviços selecionados");
            return;
        }


        const calcularValorTotal = () => {

            let acrescimo = null;
            if (os.informacoesBasicas.acrescimo && os.informacoesBasicas.acrescimo[0] === 'R') {
                acrescimo = parseFloat(os.informacoesBasicas.acrescimo.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.'));
            } else {
                acrescimo = parseFloat(os.informacoesBasicas.acrescimo);
            }

            const valorPeliculas = calcularValorPeliculas();
            const valorTotal = parseFloat(valorPeliculas) + parseFloat(acrescimo);

            console.log(parseFloat(valorPeliculas), parseFloat(acrescimo))
            return valorTotal.toFixed(2);
        };
        os.valor_total = calcularValorTotal();
        os.id = id;
        console.log(os)

        //removendo as keys peliculas e clientes para corrigir o 413
        const data_endpoint = Object.fromEntries(
            Object.entries(os).filter(([key]) => key !== "peliculas" && key !== "clientes")
        );

        try {
            const response = await Api.put("EditarArquitetura", data_endpoint, {
                headers: { 'Content-Type': 'application/json' }
            });
            os.informacoesBasicas.idOS = response.data.insertID
            console.log(response.data.insertID)
            setStatus({
                success: true,
                message: "OS cadastrada com sucesso."
            })
        } catch (error) {
            console.log(error.response.data.error == 'Quantidade não informada')
            setStatus({
                success: false,
                message: error.response.data.error == 'Quantidade não informada' ? "Preencha a quantidade das películas selecionadas" : "Erro ao cadastrar OS."
            })
            console.log(error);
            return;
        }

        const email = os.clientes.find((cliente) => cliente.id == os.informacoesBasicas.id_cliente).email || os.clientes.find((cliente) => cliente.id == os.informacoesBasicas.id_cliente).email_profissional || null;
        os.informacoesBasicas.idOS = id;
        setModalData({
            informacoesBasicas: os.informacoesBasicas,
            vidros: os.vidros,
            aplicadores: os.aplicadores,
            novoCliente: os.novoCliente,
            funcionarios: os.funcionarios,
            clientes: os.clientes,
            peliculas: os.peliculas,
            email: email,
        })
        setShowModalConfirm(true);

    }


    const calculaValor_final = () => {
        const subtotal = calcularValorTotal();
        let desconto = 0;

        if (os.informacoesBasicas && os.informacoesBasicas.desconto != undefined) {
            if (os.informacoesBasicas.desconto !== undefined && os.informacoesBasicas.desconto !== null && os.informacoesBasicas.desconto !== '') { //se tiver um desconto
                if (os.informacoesBasicas.desconto[0] === 'R') {
                    desconto = parseFloat(os.informacoesBasicas.desconto.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.'));
                } else {
                    desconto = parseFloat(os.informacoesBasicas.desconto);
                }
                return (subtotal - desconto).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
            }
        }

        return subtotal.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    }

    const calcularValorTotal = () => {

        let acrescimo = 0; //setar para watch('acrescimo') somente se for numérico, nao nulo, nao indefinido e nao vazio
        if (os.informacoesBasicas && os.informacoesBasicas.acrescimo !== undefined && os.informacoesBasicas.acrescimo !== null && os.informacoesBasicas.acrescimo !== '') {
            if (os.informacoesBasicas.acrescimo[0] === 'R') {
                acrescimo = parseFloat(os.informacoesBasicas.acrescimo.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.'));
            } else {
                acrescimo = parseFloat(os.informacoesBasicas.acrescimo);
            }
        }


        const valorPeliculas = calcularValorPeliculas();
        const valorTotal = parseFloat(valorPeliculas) + acrescimo;
        // console.log(valorProdutos);

        return valorTotal.toFixed(2);
    };

    const calcularValorPeliculas = () => {
        //para cada os.vidros pegar o valor de altura, largura e quantidade e multiplicar pelo preço obtido do id_pelicula no objeto peliculas
        let valorTotal = 0;
        //se existir uma os.vidros e não for vazio
        if (os.vidros && os.vidros.length > 0) {
            os.vidros.forEach((vidro) => {
                //se tiver altura largura e quantidade
                if (vidro.altura !== undefined && vidro.altura !== null && vidro.altura !== '' &&
                    vidro.largura !== undefined && vidro.largura !== null && vidro.largura !== '' &&
                    vidro.quantidade !== undefined && vidro.quantidade !== null && vidro.quantidade !== '' &&
                    vidro.id_pelicula !== undefined && vidro.id_pelicula !== null && vidro.id_pelicula !== '') {

                    const valor_m_vidro = os.peliculas.find((pelicula) => pelicula.id == vidro.id_pelicula).valor_venda;
                    const valor = parseFloat(vidro.altura.replace(",", ".")) * parseFloat(vidro.largura.replace(",", ".")) * parseFloat(vidro.quantidade.replace(",", ".")) * parseFloat(valor_m_vidro);
                    valorTotal += valor;
                }
            });
            return valorTotal.toFixed(2);
        }
        else {
            return 0.00;
        }

    };


    return (
        <div className='page-full'>
            <form className='bg-white'>

                <div className="header-page-full mb-4 cursor-pointer" onClick={() => window.history.back()} >
                    <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                </div>
                <div className="pt-5 m-5">
                    <div className="row d-flex justify-content-center align-items-top">
                        <div className='col-md-1 d-none d-xl-block'>
                            <span className="ant-avatar-circle nex-avatar default circle-icon">
                                <FontAwesomeIcon icon="fa-solid fa-table-cells-large" />
                            </span>
                        </div>
                        <div className="col-xl-8 col-12 mb-5">
                            <h1 className="h3 mb-3 fw-bold">Edição O.S. Arquitetura</h1>
                            <div className="mb-3">
                                {os.funcionarios && os.clientes && os.informacoesBasicas ?
                                    <div>
                                        {status.message && (
                                            <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
                                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                                <div className="alert-icon">
                                                    <i className="far fa-fw fa-bell"></i>
                                                </div>
                                                <div className="alert-message">
                                                    {status.message}
                                                </div>
                                            </div>
                                        )}
                                        <div className="row">

                                            <div className="col-xl-4 col-10 mb-3">
                                                <label className="form-label">Nome do Cliente</label>
                                                <Select
                                                    placeholder="Selecione"
                                                    options={os.clientes.map((cliente) => {
                                                        const nome = cliente.nome || cliente.nome_fantasia || cliente.nome_contato || "Nome não disponível";
                                                        return {
                                                            value: cliente.id,
                                                            label: `${nome}`,
                                                        };
                                                    }).sort((a, b) => a.label.localeCompare(b.label))}
                                                    onChange={handleClienteChange}
                                                    value={
                                                        os.informacoesBasicas.id_cliente && {
                                                            value: os.informacoesBasicas.id_cliente,
                                                            label: `${os.clientes.find((cliente) => cliente.id === os.informacoesBasicas.id_cliente)?.nome || os.clientes.find((cliente) => cliente.id === os.informacoesBasicas.id_cliente)?.nome_fantasia || os.clientes.find((cliente) => cliente.id === os.informacoesBasicas.id_cliente)?.nome_contato || 'Nome não disponível'}`
                                                        }
                                                    }

                                                    isDisabled={NovoClienteAdicionado}
                                                />
                                            </div>
                                            <div className='col-xl-1 col-2 mb-3'>
                                                <label className="form-label">&nbsp;</label>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary fw-bold  w-100"
                                                    onClick={() => setShowModalEdicaoCliente(true)}
                                                    disabled={os.informacoesBasicas ? os.informacoesBasicas.id_cliente == null : true}
                                                >
                                                    <FontAwesomeIcon icon="fa-solid fa-pencil" />
                                                </button>
                                            </div>

                                            {console.log("id cliente: ", os.informacoesBasicas.id_cliente)}
                                            <div className="col-xl-4 col-6 mb-3">
                                                <label className="form-label">Nome do Vendedor</label>
                                                <Select
                                                    placeholder="Selecione"
                                                    options={os.funcionarios
                                                        .map((funcionario) => {
                                                            const nome = funcionario.apelido || funcionario.nome;
                                                            return {
                                                                value: funcionario.id,
                                                                label: `${nome}`,
                                                            };
                                                        })
                                                        .sort((a, b) => a.label.localeCompare(b.label)) // Ordenar alfabeticamente pelo rótulo
                                                    }
                                                    value={os.informacoesBasicas.id_funcionario && { value: os.informacoesBasicas.id_funcionario, label: `${os.funcionarios.find((funcionario) => funcionario.id === os.informacoesBasicas.id_funcionario)?.apelido || os.funcionarios.find((funcionario) => funcionario.id === os.informacoesBasicas.id_funcionario)?.nome} ` }}
                                                    onChange={handleFuncionarioChange}
                                                />

                                            </div>
                                            <div className="col-xl-3 col-6 mb-3">
                                                <label className="form-label">Data de Início</label>
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    name="data_inicio"
                                                    onChange={(e) => handleFieldChange("data_inicio", e.target.value)}
                                                    value={os.informacoesBasicas.data_inicio ? os.informacoesBasicas.data_inicio : ""}
                                                />
                                            </div>

                                        </div>
                                    </div> : null}
                                <hr />

                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <h2 className="h4">Vidros</h2>

                                    <span className="btn btn-primary rounded-5" onClick={handleAddVidro}>
                                        <FontAwesomeIcon icon="fa-solid fa-plus" />
                                    </span>
                                </div>
                                {os.vidros.map((vidro) => (
                                    <div className="row bg-light mb-2 py-2" key={vidro.id}>
                                        <div className="col-md-2 mb-3">
                                            <label className="form-label">Altura</label>
                                            <input
                                                type="number" step="any" min="0"
                                                className="form-control"
                                                name={`altura_${vidro.id}`}
                                                value={vidro.altura}
                                                onChange={(e) => handleVidroChange(vidro.id, 'altura', e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label className="form-label">Largura</label>
                                            <input
                                                type="number" step="any" min="0"
                                                className="form-control"
                                                name={`largura_${vidro.id}`}
                                                value={vidro.largura}
                                                onChange={(e) => handleVidroChange(vidro.id, 'largura', e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label className="form-label">Quantidade</label>
                                            <input
                                                type="number" min="0"
                                                className="form-control"
                                                name={`quantidade_${vidro.id}`}
                                                value={vidro.quantidade}
                                                onChange={(e) => handleVidroChange(vidro.id, 'quantidade', e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label">Pelicula</label>
                                            <Select
                                                placeholder="Selecione"
                                                options={os.peliculas.map((pelicula) => {
                                                    return {
                                                        value: pelicula.id,
                                                        label: `${pelicula.nome}`,
                                                    };
                                                }
                                                )}
                                                value={vidro.id_pelicula && { value: vidro.id_pelicula, label: `${os.peliculas.find((pelicula) => pelicula.id === vidro.id_pelicula)?.nome}` }}
                                                onChange={(selectedOption) => handleVidroChange(vidro.id, 'id_pelicula', selectedOption.value)}
                                            />
                                        </div>
                                        <div className="col-md-8 mb-3">
                                            <label className="form-label">Local de Aplicação</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name={`local_aplicacao_${vidro.id}`}
                                                value={vidro.local_aplicacao}
                                                onChange={(e) => handleVidroChange(vidro.id, 'local_aplicacao', e.target.value)}
                                            />
                                        </div>


                                        <div className="col-md-3 mb-3">
                                            <label className="form-label">Valor</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="basic-url"
                                                aria-describedby="basic-addon3"
                                                value={'R$ ' + calculaSubtotalVidro(vidro.id)}
                                                readOnly
                                            />
                                        </div>
                                        <div className="col-md-1 mb-3 align-self-end">
                                            <button
                                                className="btn btn-danger btn-remove"
                                                onClick={() => handleRemoveVidro(vidro.id)}
                                            >
                                                <FontAwesomeIcon icon="fa-regular fa-trash-can" />
                                            </button>
                                        </div>
                                    </div>
                                ))}
                                <hr />
                                {os.funcionarios && os.clientes ?
                                    <div className="row">
                                        <div className="col-md-12 mb-3">
                                            <label className="form-label">Aplicador(es)</label>
                                            <Select
                                                placeholder="Selecione"
                                                options={os.funcionarios
                                                    .map((funcionario) => {
                                                        const nome = funcionario.apelido || funcionario.nome;
                                                        return {
                                                            value: funcionario.id,
                                                            label: `${nome}`,
                                                        };
                                                    }
                                                    )
                                                    .sort((a, b) => a.label.localeCompare(b.label)) // Ordenar alfabeticamente pelo rótulo
                                                }
                                                isMulti
                                                value={
                                                    os.informacoesBasicas && os.informacoesBasicas.aplicador ?
                                                        os.informacoesBasicas.aplicador.map(aplicadorID => ({
                                                            value: aplicadorID,
                                                            label: os.funcionarios.find((funcionario) => funcionario.id === parseInt(aplicadorID))?.apelido || os.funcionarios.find((funcionario) => funcionario.id === parseInt(aplicadorID))?.nome || "",
                                                        })) :
                                                        []
                                                }
                                                onChange={(selectedOptions) => {
                                                    const selectedFuncionarios = selectedOptions.map(option => option.value);
                                                    handleFieldChange("aplicador", selectedFuncionarios)
                                                }
                                                }

                                            />
                                        </div>
                                    </div> : null}
                                <hr />

                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <label className="form-label">Observações</label>
                                        <TextareaAutosize
                                            className="form-control"
                                            minRows={1}
                                            onChange={(e) => handleFieldChange("observacao", e.target.value)}
                                            value={os.informacoesBasicas && os.informacoesBasicas.observacao ? os.informacoesBasicas.observacao : ""}
                                        />
                                    </div>
                                </div>

                                <div className="row justify-content-end">
                                    <div className="col-md-3 mb-3 mt-4">
                                        <label>Desconto</label>
                                        <div className='input-group'>
                                            <CurrencyInput
                                                defaultValue={os.informacoesBasicas && os.informacoesBasicas.desconto ? os.informacoesBasicas.desconto : ""}
                                                onChange={(event, value) =>
                                                    handleFieldChange('desconto', event.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.'))}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3 mt-4">
                                        <label>Acréscimo</label>
                                        <div className='input-group'>
                                            <CurrencyInput
                                                defaultValue={os.informacoesBasicas && os.informacoesBasicas.acrescimo ? os.informacoesBasicas.acrescimo : ""}
                                                onChange={(event, value) => {
                                                    // setValue('acrescimo', event.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.'));
                                                    handleFieldChange('acrescimo', event.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.'));
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-3 mt-4">
                                        <label>Subtotal</label>
                                        <div className='input-group'>
                                            <span className="input-group-text">R$</span>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={Number(calcularValorTotal()).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', currencyDisplay: 'narrowSymbol' }).replace("R$", "")}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-end">
                                    <div className="col-md-4 mb-3">
                                        <label><b>Total</b></label>
                                        <div className='input-group'>
                                            <span className="input-group-text">R$</span>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={calculaValor_final()}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-page-full">
                    <div className="footer-client">
                        <button type="button" className="btn btn-light button-large fw-bold" onClick={() => submitOS()}> EDITAR OS </button>
                        <button type="button" className="btn text-white button-large fw-bold" onClick={() => window.history.back()}> CANCELAR </button>
                    </div>
                </div>


                <Modal centered show={showModalConfirm} onHide={() => { setShowModalConfirm(false) }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmação</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        <label className="form-label">As alterações da OS foram salvas, deseja gerar o PDF ou enviar por email ?</label>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" onClick={() => { Certificado(modalData); setShowModalConfirm(true); window.location.href = "/listar-arquitetura" }}>Gerar PDF</button>
                        {modalData.email && <button className={`btn ${isRequestSuccessful ? 'btn-success disabled' : buttonClicked ? 'btn-primary disabled' : 'btn-primary'}`} onClick={handleButtonClick}>{buttonClicked ? (isRequestSuccessful ? 'Enviado' : 'Enviando...') : 'Enviar Email'}</button>}
                        <button className="btn btn-secondary" disabled={buttonClicked || isRequestSuccessful} onClick={() => { setShowModalConfirm(false); window.location.href = "/listar-arquitetura"; }}>Fechar</button>
                    </Modal.Footer>
                </Modal>

                <Modal size='lg' centered show={showModalEdicaoCliente} onHide={() => { setShowModalEdicaoCliente(false); }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Informações do Cliente</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <EditarClienteOS setShowModalEdicaoCliente={setShowModalEdicaoCliente} cliente={os && os.informacoesBasicas && os.informacoesBasicas.id_cliente ? os.informacoesBasicas.id_cliente : null} />

                    </Modal.Body>
                </Modal>





            </form>
        </div >
    )
}

export default NovaArquitetura;