import React from 'react';
import Login from './Login';

function LoginLayout() {
    return (
        <div className="login-page">
            <div className="login-background">
                <Login />
            </div>
        </div>
    );
}

export default LoginLayout;
